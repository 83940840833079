exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arena-schedule-js": () => import("./../../../src/pages/arena-schedule.js" /* webpackChunkName: "component---src-pages-arena-schedule-js" */),
  "component---src-pages-cashdrawer-js": () => import("./../../../src/pages/cashdrawer.js" /* webpackChunkName: "component---src-pages-cashdrawer-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jellybean-dance-js": () => import("./../../../src/pages/jellybean-dance.js" /* webpackChunkName: "component---src-pages-jellybean-dance-js" */),
  "component---src-pages-menu-beverages-js": () => import("./../../../src/pages/menu-beverages.js" /* webpackChunkName: "component---src-pages-menu-beverages-js" */),
  "component---src-pages-menu-food-beverages-js": () => import("./../../../src/pages/menu-food-beverages.js" /* webpackChunkName: "component---src-pages-menu-food-beverages-js" */),
  "component---src-pages-menu-food-js": () => import("./../../../src/pages/menu-food.js" /* webpackChunkName: "component---src-pages-menu-food-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-order-online-js": () => import("./../../../src/pages/order-online.js" /* webpackChunkName: "component---src-pages-order-online-js" */),
  "component---src-pages-orders-queue-js": () => import("./../../../src/pages/orders/queue.js" /* webpackChunkName: "component---src-pages-orders-queue-js" */),
  "component---src-pages-school-lunch-js": () => import("./../../../src/pages/school-lunch.js" /* webpackChunkName: "component---src-pages-school-lunch-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

